/*------------------------------------*\
  Cms Variables
\*------------------------------------*/

:root {
    // login form
    --login-form-margin: 15px;
    --login-form-width: 450px;
    --login-form-height: 450px;

    // Published toggle
    --published-toggle-height: 20px;
    --published-toggle-width: 40px;
    --published-toggle-border-radius: 10px;
}