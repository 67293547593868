/*------------------------------------*\
  Seo Preview
\*------------------------------------*/

// Google Preview
.cms-seo-preview--google {
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 10px;
    font-family: arial,sans-serif;
    color: #202124;
    font-style: normal;
    font-size: 14px;
}

.cms-seo-preview__url--google {
    line-height: 1.3;
}

.cms-seo-preview__path--google {
    color: #5f6368;
}

.cms-seo-preview__title--google {
    padding-top: 5px;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 3px;
    font-weight: 400;
    color: #1a0dab;
}

.cms-seo-preview__description--google {
    color: #4d5156;
    line-height: 1.58;
}

// OG Preview
.cms-seo-preview--og {
    border: 1px solid #dadde1;
    width: 524px;
    background: #f2f3f5;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
}

.cms-seo-preview__img--og {
    height: 273.64444444444px;
    width: 524px;
    object-fit: cover;
    object-position: center center;
}

.cms-seo-preview__url--og {
    padding: 10px 12px 0;
    font-weight: 600;
    line-height: 11px;
    color: #606770;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cms-seo-preview__title--og {
    padding: 0 12px;
    font-size: 16px;
    line-height: 20px;
    margin: 3px 0 0;
    padding-top: 2px;
    max-height: 110px;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: normal;
    color: #1d2129;
    font-weight: 600;
    max-height: 110px;
}

.cms-seo-preview__description--og {
    margin: 0 12px 10px;
    color: #606770;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
    max-height: 80px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
