/*------------------------------------*\
  Login Form
\*------------------------------------*/

.cms-login-form {
    width: var(--login-form-width);
    min-height: var(--login-form-height);
    margin: var(--login-form-margin);
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.cms-login-form__main {
    flex: 1 0;
    display: flex;
    flex-direction: column;
}


.cms-login-form__step {
    flex: 1 0;
    display: flex;
    flex-direction: column;
}

.cms-login-form__logo {
    width: 180px;
    margin-bottom: 15px;
}

.cms-login-form__email {
    display: flex;
    justify-content: center;
}

.cms-login-form__actions {
    margin-top: auto;
    padding-top: 20px;
    justify-content: space-between;
}