/*------------------------------------*\
  Account menu
\*------------------------------------*/

.ak-account-menu__profile-img {
    margin-top: -11px;
    margin-bottom: -11px;
    border-radius: 50%;
    max-width: 38px;

    @media(min-width: 768px) {
        margin-right: 10px;
    }
}