/*------------------------------------*\
  Published toggle
\*------------------------------------*/

.cms-published-toggle {
    display: flex;

    --toggle-height: var(--published-toggle-height);
    --toggle-width: var(--published-toggle-width);
    --toggle-border-radius: var(--published-toggle-border-radius);
    --toggle-active-background: var(--color-success);
    --toggle-background: rgba(var(--color-1-rgb), .2);

    .ak-form__toggle-checkmark {
        top: 0;
    }
}

.cms-published-toggle__link {
    display: flex;
    align-items: center;
    gap: 3px;
    color: rgba(var(--color-text-rgb), .6);
    text-decoration: none;
    margin-top: -4px;
    line-height: 1;
    font-size: .81rem;

    &:hover {
        color: var(--color-1);
    }
}
